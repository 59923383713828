import http from "@/utils/http";
import { IPageSize } from "@/typings/index";
import { removeEmptyStringVal } from '@/utils/tool'

// 指令接收 - 列表查询
export const getTaskList = (params: IPageSize) =>
  http({
    url: "/api-instruction/instructionreceive/allCommentatorIns",
    method: "get",
    params: removeEmptyStringVal(params),
  });

// 指令接收 - 详情
export interface IInstructionInfoParams {
  instructionId?: string,
  receiveId?: string,
  receiveCompleteTime?: string,
  receiveReq?: string,
  fileId?: string,
}

export const getInstructionAcceptInfo = (params: IInstructionInfoParams) => http({
  url: '/api-instruction/instructionreceive/insReceiveDetail',
  method: 'get',
  params,
});

// 指令接收 - 指令接收(未接收且查看详情)
export const receiveInstruction = (params: IInstructionInfoParams) => http({
  url: '/api-instruction/instructionreceive/commentatorInsReceive',
  method: 'post',
  data: params,
});

// 指令接收 - 指令完成
export const completeInstruction = (params: IInstructionInfoParams) => http({
  url: '/api-instruction/instructionreceive/commentatorInsComplete',
  method: 'post',
  data: params,
});

interface IShareParams {
  url: string
}
// 微信分享
export const getSignatureByUrl = (params: IShareParams) => http({
  url: '/api-editingChannels/wxapp/getSignatureByUrl',
  method: 'get',
  params: params,
});
