<template>
  <div class="task-detail">
    <div class="content">
      <van-cell-group class="cell-group">
        <van-cell title="指令标题" :value="info.instructionTitle" />
        <van-cell title="指令渠道" :value="parsedInstructionChannel" />
        <van-cell title="指令类型" :value="parsedInstructionType" />
        <van-cell title="指令状态">
          <div :class="parsedInstructionStatus.class">
            {{ parsedInstructionStatus.status }}
          </div>
        </van-cell>
      </van-cell-group>

      <!--
        -- 当前只有两个渠道
          微信: 内容标题 内容链接
          抖音: 内容标题 抖音口令
       -->
      <van-cell-group class="cell-group">
        <van-cell title="内容标题" :value="info.articleTitle" />
        <!-- 这边拆出来给是为了以后拓展其他渠道清晰 -->
        <van-cell v-if="info.channel === 1" title="内容链接">
          <div class="link" @click="toComplate(info.contentUrl)">
            {{ info.contentUrl }}
          </div>
          <template #right-icon>
            <van-button
              type="primary"
              size="mini"
              @click="copy(info.contentUrl, '链接')"
            >
              <i class="rmy-icon rmy-icon-a-gongneng-fuzhi1" aria-hidden="true"></i>
              复制</van-button
            >
          </template>
        </van-cell>
        <van-cell v-if="info.channel === 3" title="抖音口令">
          <div class="link" @click="copy(info.contentUrl, '口令')">
            {{ info.contentUrl }}
          </div>
        </van-cell>
      </van-cell-group>

      <van-cell-group class="cell-group">
        <van-cell title="发送单位" :value="info.tenantName" />
        <van-cell title="下发时间" :value="info.sendTime" />
        <van-cell
          title="时间限制"
          v-if="info.limitTime"
          :value="info.limitTime + ' 前完成'"
        />
      </van-cell-group>

      <div class="instruction-req-header">指令要求</div>
      <van-cell-group class="cell-group instruction-req-cell-group">
        <div class="html-cont" v-html="info.instructionReq"></div>
      </van-cell-group>

      <van-cell-group
        class="cell-group"
        v-if="info.fileVos && info.fileVos.length"
      >
        <van-cell
          v-for="(item, index) in info.fileVos"
          :key="index"
          :title="!index ? '附件' : ''"
        >
          <div class="link" @click="filePreview(item.url)">
            {{ item.fileName }}
          </div></van-cell
        >
      </van-cell-group>
    </div>

    <div
      class="footer"
      v-if="info.instructStatus !== '2' && info.instructionId"
    >
      <!-- 在微信渠道-转发时候提供手动完成 -->
      <van-button
        :class="{newButton:getBtnVal()==='去转发'}"
        v-if="info.channel === 1 && info.instructionType === 1"
        :plain="getBtnVal()==='去转发'"
        :color="isBeyondLimitTime ? '#C8C8C8' : '#467FFE'"
        block
        round
        hairline
        :disabled="isDisabled"
        @click="toComplateBySelf"
      >
        手动完成
      </van-button>
      <van-button
        :color="isBeyondLimitTime ? '#C8C8C8' : '#467FFE'"
        block
        round
        hairline
        :disabled="isDisabled"
        @click="toComplate"
      >
        {{ getBtnVal() }}
      </van-button>
    </div>

    <van-share-sheet
      v-model:show="showShare"
      title="立即分享给好友"
      :options="shareOptions"
      @select="onSelect"
    />
  </div>
</template>

<script lang="ts">
import { useRoute } from "vue-router";
import {
  IInstructionInfoParams,
  getInstructionAcceptInfo,
  completeInstruction,
  getSignatureByUrl,
  receiveInstruction
} from "@/api/task";
import { getTokenByUnionId } from "@/api/web";
import {
  parseInstructionType,
  parseInstructionStatus,
  parseInstructionChannel,
} from "@/utils/tool";
import {
  reactive,
  ref,
  onMounted,
  defineComponent,
  toRefs,
  computed
} from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import { Toast } from "vant";
import router from "@/router";
import useClipboard from "vue-clipboard3";
import wx from "weixin-js-sdk-ts";

interface IfileItem {
  fileName: string,
  url: string,
}

interface Istate {
  info: {
    limitTime: string,
    channel: number,
    instructionType: number,
    articleTitle: string,
    contentUrl: string,
    instructionId: string,
    instructionTitle: string,
    receiveId: string,
    instructStatus: string,
    tenantName: string,
    sendTime: string,
    instructionReq: string,
    fileVos: IfileItem[],
    tenantLogoUrl: string,
    imageUrl: string
  }
}

export default defineComponent({
  setup() {
    const route = useRoute();
    const store = useStore();

    let state: Istate = reactive({
      info: {
        limitTime: "",
        channel: 0,
        instructionType: 0,
        articleTitle: "",
        contentUrl: "",
        instructionId: "",
        instructionTitle: "",
        receiveId: "",
        instructStatus: "",
        tenantName: '',
        sendTime: '',
        instructionReq: '',
        fileVos: [],
        tenantLogoUrl: '',
        imageUrl:''
      }
    });

    let isBeyondLimitTime = ref(true); //  是否超出时间限制 用来处理按钮逻辑
    let parsedInstructionType = ref("");
    let parsedInstructionStatus = ref({
      class: '',
      status: ''
    });
    let parsedInstructionChannel = ref("");

    // 返回操作按钮文案
    const getBtnVal = () => {
      // 微信渠道
      if (state.info.channel === 1 && state.info.instructionType !== 5) {
        return `去${parsedInstructionType.value}`;
      }

      return "手动完成";
    };

    const getInfo = async (v?: IInstructionInfoParams) => {
      const res = await getInstructionAcceptInfo(v || route.query);
      if ((res as any).resp_code === 0) {
        const tenantLogoUrl = 'https://ymr-test.oss-cn-hangzhou.aliyuncs.com/testfile/f84c1ef1-02d5-4576-9be8-f131b09f818f.png'; // 分享logo，默认融媒云logo
        state.info = (res as any).datas;
        state.info.tenantLogoUrl = state.info.imageUrl ||state.info.tenantLogoUrl || tenantLogoUrl;
        // 修改限制时间长度
        let limitTime = state.info.limitTime;
        if (limitTime) {
          const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
          isBeyondLimitTime.value = now > limitTime;
        } else {
          isBeyondLimitTime.value = false;
        }
        state.info.limitTime = limitTime && limitTime.substring(0, 16);
        parsedInstructionType.value = parseInstructionType(
          (res as any).datas.instructionType
        );
        parsedInstructionStatus.value = parseInstructionStatus(
          (res as any).datas.instructStatus
        );
        parsedInstructionChannel.value = parseInstructionChannel(
          (res as any).datas.channel
        );

        getBtnVal();
      }
    };

    // 已过期、完成状态禁用按钮
    const isDisabled = computed(() => {
      return false;
    });

    const showShare = ref(false);
    const shareOptions = reactive([]);

    const toComplateBySelf = () => {
      if (isBeyondLimitTime.value) {
        Toast({
          type: "fail",
          message: "该任务已过期"
        });
        return;
      }
      router.push({
        name: "taskComplate",
        query: {
          receiveId: state.info.receiveId,
          instructionId: state.info.instructionId
        }
      });
    };

    /**
     * 任务完成规则
     * 微信---
     *    转发：通过分享好友，朋友圈，分享成功后调用
     *    阅读：点击链接跳转调用
     *    评论：手动完成（暂定）爬虫抓取结果，顶部提示文案（有延时）
     * 抖音---
     *    转发：手动完成 oss链接地址（暂定）
     *    ---- 以下可能要手动完成
     *    阅读：手动完成 （复制口令截图）
     *    评论：手动完成 爬虫爬取（绑定需要输入抖音昵称）顶部提示文案（有延时）
     *    点赞：手动完成（点赞后截图）
     */
    const toComplate = async (url: string) => {
      /**
       * 任务过期处理
       *  点击链接-跳转
       *  点击按钮-提示过期
       */
      if (isBeyondLimitTime.value) {
        if (typeof url === "string") {
          window.open(url, "_self");
          return
        }
        Toast({
          type: "fail",
          message: "该任务已过期"
        });
        return;
      }

      /**
       * 微信-阅读-待完成
       *  跳转链接-完成
       */
      if (state.info.channel === 1 && state.info.instructionType === 4) {
        const params = {
          instructionId: state.info.instructionId || "",
          receiveId: state.info.receiveId || ""
        };

        await completeInstruction(params as any);

        if (state.info.contentUrl) {
          window.open(state.info.contentUrl, "_self");
          return;
        }
        Toast({
          type: "fail",
          message: "URL地址错误"
        });
        return;
      }

      // 判断是否微信-转发-待完成
      if (
        state.info.channel === 1 &&
        state.info.instructionType === 1 &&
        state.info.instructStatus === "1"
      ) {

      //  state.info.articleTitle.replace(/\{/g, '')
      //  const str=state.info.articleTitle.replace(/\|/g, ',');

        router.push({
          name: "taskShare",
          query: {
            url: state.info.contentUrl,
            title:  state.info.articleTitle,
            logo: state.info.tenantLogoUrl,
            // debug:1,
            huancun:Math.random()
          },
          params: {
            instructionId: state.info.instructionId,
            receiveId: state.info.receiveId,
          }
        });
        return;
      }

      // 判断是否微信-转发-已完成 直接跳链接 不用分享
      if (
        state.info.channel === 1 &&
        state.info.instructionType === 1 &&
        state.info.instructStatus === "2"
      ) {
        window.open(state.info.contentUrl, "_self");
        return;
      }

      // 链接点击带Url 直接跳转了
      if (typeof url === "string") {
        window.open(url, "_self");
        return;
      }

      // 其余手动完成
      toComplateBySelf();
    };

    const onSelect = (option: any) => {
      console.log("option", option);
    };

    const filePreview = (url: string) => {
      window.open(url, "_self");
    };

    /**
     * 复制抖音口令
     */
    const { toClipboard } = useClipboard();
    const copy = async (url: string, msg: string) => {
      try {
        await toClipboard(url);
        Toast({
          type: "success",
          message: `${msg}复制成功`
        });
      } catch (err) {
        Toast({
          type: "fail",
          message: (err as Error).message
        });
      }
    };

    /**
     * 公众号点击详情链接免登录处理
     * 通过url 带的参数 unionId instructionId receiveId
     * unionId -> 获取 token
     */
    const init = async () => {
      const { unionId, instructionId, receiveId } = route.query as any;
      if (unionId && instructionId && receiveId) {
        // 每次打开详情都重新登录下防止被顶掉导致登录失效问题，公众号详情链接跳转-获取基本登录信息
        const tokenRes = await getTokenByUnionId({ unionId });
        if ((tokenRes as any).resp_code === 0) {
          // 处理返回结果
          const {
            refresh_token,
            token_type,
            access_token,
            expires_in,
          } = (tokenRes as any).datas;
          store.commit('setToken', { access_token, token_type, expires_in, refresh_token });
        } else {
          router.replace({
            name: "Login"
          });
          return;
        }
      }

      if (instructionId && receiveId) {
        // 免登如果状态为未接收要改为已接收
        await receiveInstruction({ receiveId });
        getInfo({ instructionId, receiveId });
      } else {
        getInfo();
      }
    };

    const initWx = async () => {
      const res = await getSignatureByUrl({
        url: encodeURIComponent(location.href.split("#")[0])
      });

      if ((res as any).resp_code === 0) {
        const config = (res as any).datas;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: config.appId, // 必填，公众号的唯一标识
          timestamp: config.timestamp, // 必填，生成签名的时间戳
          nonceStr: config.nonceStr, // 必填，生成签名的随机串
          signature: config.signature, // 必填，签名
          jsApiList: ["hideMenuItems"], // 必填，需要使用的JS接口列表
          openTagList: []
        });

        wx.ready(() => {
          wx.hideMenuItems({
            menuList: [
              "menuItem:share:appMessage",
              "menuItem:share:timeline",
              "menuItem:share:qq",
              "menuItem:share:weiboApp",
              "menuItem:favorite",
              "menuItem:share:QZone",
              "menuItem:copyUrl"
            ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
          });
        });
      }
    };

    onMounted(() => {
      let isPageHide = false;
      window.addEventListener("pageshow", function() {
        if (isPageHide) {
          window.location.reload();
        }
      });
      window.addEventListener("pagehide", function() {
        isPageHide = true;
      });

      init();
      initWx();
    });

    return {
      ...toRefs(state),
      parsedInstructionType,
      parsedInstructionStatus,
      parsedInstructionChannel,
      isBeyondLimitTime,
      getBtnVal,
      toComplate,
      toComplateBySelf,
      showShare,
      shareOptions,
      onSelect,
      filePreview,
      copy,
      isDisabled
    };
  }
});
</script>

<style lang="scss" scoped>
.task-detail {
  display: flex;
  flex-direction: column;
  background: $BACKGROUND_COLOR;
  height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  height: calc(100vh - var(--vh-offset, 0px));
  overflow: hidden;
  .content {
    flex: 1;
    height: 0;
    overflow: auto;
    .cell-group {
      margin-top: 8px;
      .link {
        @include overflow-text-l2;
        -webkit-line-clamp: 1;
        color: $PRIMARY;
      }
      .copy-img {
        width: 16px;
        vertical-align: bottom;
      }
    }
    .instruction-req-header {
      color: $BASE_FONT_COLOR;
      font-weight: bold;
      font-size: $BASE_FONT_SIZE;
      padding: 16px;
    }
    .cell-group.instruction-req-cell-group {
      padding: 16px;
      min-height: 100px;
      max-height: 600px;
      overflow-y: auto;

      .html-cont {
        word-break: break-word;
        text-align: justify;
      }
    }
  }
  .footer {
    padding: 16px;
    display: flex;

    :deep(button) {
      margin: 0 6px;
    }
    .newButton{
      width: 130px;
      overflow: hidden;
    }
  }
}
</style>
